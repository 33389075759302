; (function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('SimpleCarousel', ['Hammer'], factory);
    } else {
        // Browser globals
        root.SimpleCarousel = factory(root.Hammer);
    }
}(typeof self !== 'undefined' ? self : this, function (Hammer) {
    'use strict';

    /**
     * @private
     * @description Default options
     */
    var DEFAULTS = {
        'pager': false,
        'autoplay': false,
        'buttons': false,
        'forecast': false
    }

    /**
     * @private
     * @function nextItem
     * @description Get next item (or first if given element is last child)
     * @param {HTMLElement} element Item 
     * @returns {HTMLElement}
     */
    function nextItem(element) {
        var target = element;
        while (element) {
            element = element.nextSibling;
            if (!element) return this.items[0];
            if (element && element.nodeName == target.nodeName) return element;
        }
    }

    /**
     * @private
     * @function prevItem
     * @description Get previous item (or last if given element is first child)
     * @param {HTMLElement} element Item 
     * @returns {HTMLElement}
     */
    function prevItem(element) {
        var target = element;
        while (element) {
            element = element.previousSibling;
            if (!element) return this.items[this.items.length - 1];
            if (element.nodeName == target.nodeName) return element;
        }
    }

    /**
     * @private
     * @function cloneItems
     * @description Clone items if items are 2, so that carousel can work correctly
     * @returns {undefined}
     */
    function cloneItems() {
        var list = this.container.querySelector('ul');
        Array.prototype.forEach.apply(this.items, [function (item, index) {
            var clone = item.cloneNode(true);
            clone.classList.add('-clone');
            list.appendChild(clone);
            return;
        }]);
    }

    /**
     * @private
     * @function createTicker
     * @description Create carousel ticker for autoplay
     * @returns {undefined}
     */
    function createTicker(element) {
        var t = document.createElement('span');
        t.classList.add('carouselticker');
        element.appendChild(t);
        return t
    }

    /**
     * @private
     * @function createPager
     * @description Create pager navigation
     * @returns {undefined}
     */
    function createPager(pagerElement) {
        if (this.pager) return;
        if (!pagerElement) {
            pagerElement = document.createElement('div');
            pagerElement.classList.add('carouselpager');
        }
        var pagerList = pagerElement.querySelector('ul')
        if (!pagerList) {
            pagerList = document.createElement('ul')
        }
        pagerElement.appendChild(pagerList);
        this.pager = new Pager(pagerElement, this);
        this.container.appendChild(pagerElement);
    }

    /**
     * @private
     * @function destroyPager
     * @description Destroy pager navigation
     * @returns {undefined}
     */
    function destroyPager() {
        if (this.pager) this.pager.destroy();
        this.pager = null;
        var pagerElement = this.container.querySelector('.carouselpager');
        if (pagerElement) pagerElement.remove();
    }

    /**
     * @private 
     * @function setAutoplay
     * @description Autoplay when visibile
     * @returns {undefined}
     */
    function setAutoplay() {
        // store in dataset
        this.container.dataset.autoplay = true;
        // play immediately if loaded or wait for window load
        if (document.readyState == 'complete') {
            if (this.visible()) this.play();
        } else {
            window.addEventListener('load', this.play);
        }
        window.addEventListener('scroll', this.onscroll, { passive: true });
    }

    /**
     * @private 
     * @function unsetAutoplay
     * @description Remove autoplay
     * @returns {undefined}
     */
    function unsetAutoplay() {
        if (this.container.classList.contains('-playing')) this.pause();
        window.removeEventListener('scroll', this.onscroll)
        try {
            delete this.container.dataset.autoplay;
        } catch (err) {
            this.container.removeAttribute('data-autoplay');
        }
    }

    /**
     * @private
     * @function createButtons
     * @description create navigation buttons
     * @returns {undefined} 
     */
    function createButtons(prev, next) {
        this.container.dataset.buttons = true;
        if (!prev) {
            prev = document.createElement('a');
            prev.classList.add('prevbutton');
            prev.setAttribute('data-prev-item', '');
            this.container.appendChild(prev);
            prev.addEventListener('click', this.prev);
        }
        if (!next) {
            next = document.createElement('a');
            next.classList.add('nextbutton');
            next.setAttribute('data-next-item', '');
            this.container.appendChild(next);
            next.addEventListener('click', this.next);
        }
        return;
    }

    /**
     * @private
     * @function destroyButtons
     * @description destroy carousel buttons
     * @returns {undefined}
     */
    function destroyButtons(prev, next) {
        if (prev) {
            prev.addEventListener('click', this.prev);
            prev.remove();
        }
        if (next) {
            next.addEventListener('click', this.next);
            next.remove();
        }
        try {
            delete this.container.dataset.buttons;
        } catch (err) {
            this.container.removeAttribute('data-buttons');
        }
    }

    /**
     * @class Carousel
     * @param {HTMLElement} element 
     * @param {Object} options 
     */
    function Carousel(element, options) {
        if (!typeof (element) === 'undefined') throw new TypeError('Container element is undefined');

        this.container = element;
        this.defaults = {};
        this.items = element.getElementsByClassName('slideitem');
        if (this.items.length == 0) return;
        this.ticker = element.querySelector('.carouselticker') ? element.querySelector('.carouselticker') : createTicker(element);
        this.hammer = (typeof (Hammer) !== 'undefined') ? new Hammer(element) : null;
        this.setup = this.setup.bind(this);
        this.ready = this.ready.bind(this);
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
        this.goto = this.goto.bind(this);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
        this.finished = this.finished.bind(this);
        this.done = this.done.bind(this);
        this.visible = this.visible.bind(this);
        this.onscroll = this.onscroll.bind(this);
        this.update = this.update.bind(this);
        this.dispatch = this.dispatch.bind(this);


        // Create a ticker element that lets the slideshow play by transitioning
        this.ticker.addEventListener('transitionend', this.next);

        this.setup(options);
        this.update();
        Array.prototype.forEach.apply(this.items, [function (item) { item.addEventListener('transitionend', this.done); }, this]);


        this.dispatch('simple-carousel:ready', {
            bubbles: true,
            cancelable: false
        });
    }

    Carousel.prototype = {
        container: null,
        items: null,
        defaults: {},
        ticker: null,
        hammer: null,
        setup: function (options) {
            // read options
            for (var opt in DEFAULTS) {
                this.defaults[opt] = (typeof (this.defaults[opt]) !== 'undefined') ? this.defaults[opt] : DEFAULTS[opt];
                if (typeof (this.container.dataset[opt]) !== 'undefined') this.defaults[opt] = this.container.dataset[opt];
                if (options && typeof (options[opt]) !== 'undefined') this.defaults[opt] = options[opt];
            }
            // handle autoplay
            if (this.defaults.autoplay) {
                setAutoplay.apply(this);
            } else {
                unsetAutoplay.apply(this);
            }
            // add/remove navigation buttons if needed
            var prevButton = this.container.querySelector('.prevbutton');
            var nextButton = this.container.querySelector('.nextbutton');

            if (this.defaults.buttons) {
                createButtons.apply(this, [prevButton, nextButton]);
            } else {
                destroyButtons.apply(this, [prevButton, nextButton]);
            }

            // add/remove pager
            if (this.defaults.pager) {
                var pagerElement = this.container.querySelector('.carouselpager');
                createPager.apply(this, [pagerElement]);
            } else {
                destroyPager.apply(this);
            }

            if (this.hammer) {
                this.hammer.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
                this.hammer.on('swipeleft', this.next);
                this.hammer.on('swiperight', this.prev);
            }

        },
        ready: function () {
            return this.container && this.items && this.items.length > 1 && this.container.querySelector('.slideitem.-current');
        },
        play: function () {
            if (this.container.classList.contains('-playing')) return;
            this.container.classList.add('-playing');
            this.dispatch('simple-carousel:play', {
                bubbles: true,
                cancelable: false
            });
        },
        pause: function () {
            if (!this.container.classList.contains('-playing')) return;
            this.container.classList.remove('-playing');
            this.dispatch('simple-carousel:pause', {
                bubbles: true,
                cancelable: false
            });
        },
        goto: function (index) {
            if (this.container.classList.contains('-transitioning') || typeof (this.items[index]) === 'undefined') return;

            var current = this.container.querySelector('.slideitem.-current');
            var current_i = Array.prototype.indexOf.apply(this.items, [current]);
            if (index == current_i) return;

            var direction;
            var gotoslide;
            if (index > current_i) {
                direction = 'forwards';
                gotoslide = 'next';

                if (index == this.items.length - 1 && current_i == 0) {
                    direction = 'backwards';
                    gotoslide = 'prev';
                }
            } else {
                direction = 'backwards';
                gotoslide = 'prev';

                if (index == 0 && current_i == this.items.length - 1) {
                    direction = 'forwards';
                    gotoslide = 'next';
                }
            }

            var prev = this.container.querySelector('.slideitem.-prev');
            var next = this.container.querySelector('.slideitem.-next');
            prev.classList.remove('-prev');
            next.classList.remove('-next');
            var slide = this.items[index]
            if (this.defaults.forecast) {
                var before = this.container.querySelector('.slideitem.-before');
                var after = this.container.querySelector('.slideitem.-after');
                if (before) before.classList.remove('-before');
                if (after) after.classList.remove('-after');

                if (direction === 'forwards') {
                    var aftern = nextItem.apply(this, [slide]);
                    var prevn = prevItem.apply(this, [current]);
                    var beforen = prevItem.apply(this, [prevn]);

                    aftern.classList.add('-after');
                    prevn.classList.add('-prev');
                    beforen.classList.add('-before');
                } else {
                    var beforen = prevItem.apply(this, [slide]);
                    var nextn = nextItem.apply(this, [current]);
                    var aftern = nextItem.apply(this, [nextn]);
                    beforen.classList.add('-before');
                    nextn.classList.add('-next');
                    aftern.classList.add('-after');
                }

            }

            slide.classList.add('-' + gotoslide);
            slide.classList.add('-in');

            current.classList.add('-out');
            this.container.classList.add('-transitioning');
            this.container.classList.add('-transition-' + gotoslide);
            this.container.classList.add('-transition-' + direction);
            var init = {
                bubbles: true,
                cancelable: true,
                detail: {
                    direction: direction,
                    enter: this.items[index]
                }
            };
            this.dispatch('simple-carousel:slide-start', init);
        },
        prev: function () {
            this.goto(Array.prototype.indexOf.apply(this.items, [this.container.querySelector('.slideitem.-prev')]));
        },
        next: function () {
            this.goto(Array.prototype.indexOf.apply(this.items, [this.container.querySelector('.slideitem.-next')]));
        },
        finished: function () {
            return this.container.classList.contains('-transitioning')
                && this.container.querySelectorAll('.slideitem.-in').length == 0
                && this.container.querySelectorAll('.slideitem.-out').length == 0;
        },
        done: function (event) {
            if (!event || event.type != 'transitionend') return;
            // if event target is going out, remove current and out
            if (event.target.classList.contains('-out') && event.target.classList.contains('-current')) {
                event.target.classList.remove('-out');
                event.target.classList.remove('-current');
            }
            // if event target is going in, add current and remove in        
            if (event.target.classList.contains('-in')) {
                event.target.classList.remove('-in');
                event.target.classList.add('-current');
                if (event.target.classList.contains('-next')) event.target.classList.remove('-next')
                if (event.target.classList.contains('-prev')) event.target.classList.remove('-prev')
            }
            // update state if all slide transitions have been completed
            if (this.finished()) {
                this.container.classList.remove('-transitioning');
                this.container.classList.remove('-transition-prev');
                this.container.classList.remove('-transition-next');
                this.container.classList.remove('-transition-forwards');
                this.container.classList.remove('-transition-backwards');
                this.update();
                this.dispatch('simple-carousel:slide-done', {
                    bubbles: true,
                    cancelable: false
                });
            }
        },
        dispatch: function (name, init) {
            var newEvent;
            try {
                newEvent = new CustomEvent(name, init)
            } catch (err) {
                newEvent = document.createEvent('CustomEvent')
                newEvent.initCustomEvent(
                    name,
                    init && typeof (init.bubbles) !== 'undefined' ? init.bubbles : true,
                    init && typeof (init.cancelable) !== 'undefined' ? init.cancelable : false,
                    init ? init.detail : null
                );
            }
            this.container.dispatchEvent(newEvent);
        },
        visible: function () {
            if (this.container.getBoundingClientRect().top < 0) return false;
            if (this.container.getBoundingClientRect().top > window.innerHeight) return false;
            return true;
        },
        onscroll: function () {
            if (!this.visible()) {
                if (this.container.classList.contains('-playing')) {
                    this.pause();
                }
            } else {
                if (!this.container.classList.contains('-playing')) {
                    this.play();
                }
            }
        },
        update: function () {
            // handle eventual addition of slides, remove clones if slides are added so that items are more than 2
            var clones = this.container.querySelectorAll('.slideitem.-clone');
            if (clones.length > 0 && this.items.length > 4) {
                Array.prototype.forEach.apply(this.items, [function (item) {
                    if (item.classList.contains('-clone')) item.remove();
                }]);
            }
            if (this.items.length == 1) return this.items[0].classList.add('-current');
            if (this.items.length == 2) cloneItems.apply(this);
            var prev = this.container.querySelector('.slideitem.-prev');
            var next = this.container.querySelector('.slideitem.-next');
            var current = this.container.querySelector('.slideitem.-current');
            var before = this.container.querySelector('.slideitem.-before');
            var after = this.container.querySelector('.slideitem.-after');
            if (prev) prev.classList.remove('-prev');
            if (next) next.classList.remove('-next');
            if (before) before.classList.remove('-before');
            if (after) after.classList.remove('-after');
            if (!current) {
                current = this.items[0];
                current.classList.add('-current');
            }

            prev = prevItem.apply(this, [current]);
            next = nextItem.apply(this, [current]);
            if (this.defaults.forecast) {
                before = prevItem.apply(this, [prev]);
                after = nextItem.apply(this, [next]);
                before.classList.add('-before');
                after.classList.add('-after');
            }
            prev.classList.add('-prev');
            next.classList.add('-next');
        },
        destroy: function () {
            Array.prototype.forEach.apply(this.items[function (item) { item.removeEventListener('transitionend', this.done); }, this]);
            if (this.container.querySelector('.carouselticker')) this.container.querySelector('.carouselticker').remove();
            if (this.container.querySelector('[data-prev-item]')) this.container.querySelector('[data-prev-item]').remove();
            if (this.container.querySelector('[data-next-item]')) this.container.querySelector('[data-next-item]').remove();
        }
    }


    /**
     * @class Pager
     * @param {HTMLElement} element
     * @param {Carousel} carousel
     */
    function Pager(element, carousel) {
        Object.defineProperties(this, {
            container: {
                value: element
            },
            carousel: {
                value: carousel
            },
            items: {
                value: null,
                writable: true
            },
            setup: {
                value: this.setup.bind(this)
            },
            update: {
                value: this.update.bind(this)
            },
            goto: {
                value: this.goto.bind(this)
            }
        });


        if (this.carousel.ready()) {
            this.setup();
        } else {
            this.carousel.container.addEventListener('simple-carousel:ready', this.setup);
        }
    }

    Pager.prototype = {
        setup: function () {
            this.items = null;
            var container = this.container;
            var fn = this.goto;
            var self = this;
            this.container.querySelector('ul').innerHTML = '';
            Array.prototype.forEach.apply(this.carousel.items, [function (slide, index, items) {
                if (slide.classList.contains('-clone')) return;
                var item = document.createElement('li');
                item.classList.add('pageritem');
                item.dataset.slideIndex = index;
                item.innerHTML = index + 1;
                this.container.querySelector('ul').appendChild(item);
                item.addEventListener('click', this.goto)
            }, this]);
            this.items = this.container.querySelectorAll('.pageritem[data-slide-index]');
            this.carousel.container.addEventListener('simple-carousel:slide-start', this.update);
            this.update();
        },
        update: function () {
            // remove "current" state from current pager item
            var current = this.container.querySelector('.pageritem.-current');
            if (current) current.classList.remove('-current');

            // get current slide
            var currentSlide = this.carousel.container.querySelector('.slideitem.-current');
            if (currentSlide.classList.contains('-out')) currentSlide = this.carousel.container.querySelector('.slideitem.-in');
            var slideIndex;
            slideIndex = Array.prototype.indexOf.apply(this.carousel.items, [currentSlide]);
            slideIndex = (slideIndex < this.items.length) ? slideIndex : slideIndex - 2;
            this.container.querySelector('.pageritem[data-slide-index="' + slideIndex + '"]').classList.add('-current');
        },
        goto: function (event) {
            var index = parseInt(event.target.dataset.slideIndex);
            if (!isNaN(index)) this.carousel.goto(index);
        },
        destroy: function () {
            this.carousel.container.removeEventListener('carousel-slide-start', this.update);
            this.carousel.container.removeEventListener('carousel-ready', this.setup);
            Array.prototype.forEach.apply(this.items, [function (item) {
                item.removeEventListener('click', this.goto);
            }, this]);
        }
    }

    return Carousel

}));
